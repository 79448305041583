import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "page" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_DynamicTable = _resolveComponent("DynamicTable")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("header", null, [
      _createElementVNode("h1", null, _toDisplayString(_ctx.$t('user.list.title')), 1),
      _createVNode(_component_router_link, { to: _ctx.newUserRoute }, {
        default: _withCtx(() => [
          _createVNode(_component_Button, {
            icon: "pi pi-plus",
            label: "New"
          })
        ]),
        _: 1
      }, 8, ["to"])
    ]),
    _createVNode(_component_DynamicTable, {
      cols: _ctx.cols,
      filtersSchema: _ctx.filters,
      service: _ctx.service,
      stateKey: _ctx.stateKey,
      onRowClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.goToDetail($event.data))),
      onEdit: _cache[1] || (_cache[1] = ($event: any) => (_ctx.goToDetail($event))),
      onDelete: _cache[2] || (_cache[2] = ($event: any) => (_ctx.onDelete($event.data)))
    }, null, 8, ["cols", "filtersSchema", "service", "stateKey"])
  ]))
}