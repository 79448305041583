import { Options, Vue }                    from "vue-class-component";
import { SessionStorageEnum }              from "@/utils/SessionStorageEnum";

import { UsersRoutesEnum } from "../../router";
import { usersService } from "@services/users.service";

import {
  DynamicTable, UserRole, UserRoleSelect,
  UserStatus, UserStatusSelect
} from "@components";

import { UserRoleEnum } from "@/model/enums/UserRoleEnum";
import { User }         from "@/model/api/User";

import { FilterMatchMode } from "primevue/api";
import { CompanyTypeEnum } from "@/model/enums/CompanyTypeEnum";

@Options({
  name: "UsersPage",

  components: {
    DynamicTable,
    UserStatus
  },

  beforeRouteEnter(to, from, next){
    console.debug("before enter users");
    if(from.name && !(from.name as string).startsWith(UsersRoutesEnum.USER_PREFIX)){
      sessionStorage.removeItem(SessionStorageEnum.TABLE_STATE_USERS);
    }

    next();
  },
  beforeRouteLeave() {
    
    if (usersService?.cancelPendingRequests) {
      
      usersService.cancelPendingRequests();
    }
  },
})
export default class UsersPage extends Vue {
  filters: any = null;

  get service() {
    return usersService;
  }

  get stateKey() {
    return SessionStorageEnum.TABLE_STATE_USERS
  }

  get newUserRoute(){
    return {
      name: UsersRoutesEnum.USER_DETAIL, 
      params: { userId: 'new' }
    }
  }

  get cols(){
    return [
      {
        field       : 'name',
        header      : this.$t('user.table.first_name'),
        placeHolder : "Name contains"
      },
      {
        field       : 'surname',
        header      : this.$t('user.table.last_name'),
        placeHolder : "Surname contains"
      },
      {
        field       : 'email',
        header      : this.$t('user.table.email'),
        placeHolder : "E-mail contains"
      },
      {
        field       : 'role',
        header      : this.$t('user.table.role'),
        filter_component: UserRoleSelect,
        filter_props: {
          showAdmin: true
        },
        component   : UserRole,
        placeHolder : "Role contains"
      },
      {
        field       : 'company.name',
        header      : "Supplier company",
        placeHolder : "Supplier company contains"
      },
      {
        field       : 'phone',
        header      : this.$t('user.table.phone'),
        placeHolder : "Phone contains"
      },
      {
        field  : 'status',
        header : this.$t('user.status'),
        filter_component: UserStatusSelect,
        component: UserStatus,
        placeHolder : "Status",
      },
    ] as {
      field?: keyof User, 
      [key:string]:any
    }[]
  }

  goToDetail(user: User) {
    this.$router.push({
      name: UsersRoutesEnum.USER_DETAIL,
      params: { userId: user.id }
    })
  }
  
  onDelete(user: User) {
    this.$confirmMessage("Are you sure to delete this User?")
      .then(r => {
        if (r) {
          this.deleteUser(user);
        }
      })
  }

  private deleteUser(user: User) {
    this.$waitFor(
      async () => {
        await usersService.deleteBySystem(user);
        this.$successMessage("User successfully deleted")
      },
      "Deleting User failed"
    )
  }
  
  private initFilter() {
    this.filters = {
      global: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS
      },
      name: {
        value: null,
        matchMode: FilterMatchMode.STARTS_WITH,
      },
      surname: {
        value: null,
        matchMode: FilterMatchMode.STARTS_WITH,
      },
      email: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS,
      },
      phone: {
        value: null,
        matchMode: FilterMatchMode.CONTAINS,
      },
      status: {
        value: null,
        matchMode: FilterMatchMode.EQUALS
      },
      role: {
        value: [UserRoleEnum.USER, UserRoleEnum.OWNER_OPERATOR],
        matchMode: FilterMatchMode.IN,
      },
      'company.name': {
        value: null,
        matchMode: FilterMatchMode.CONTAINS
      },
    };
  }

  created() {
    this.initFilter();
  }
}
