import { User }               from "@/model/api/User";
import { usersService }       from "@services/users.service";
import { Options, Vue }       from "vue-class-component";
import { Prop }               from "vue-property-decorator";

import { UsersRoutesEnum }    from "../../router";
import { authStore }          from "@/modules/auth/store";

import {
  ImageInput,
  PageHeader,
  StateAutocomplete
} from "@components";

import { UserStatusEnum } from "@/model/enums/UserStatusEnum";
import { UserRoleEnum } from "@/model/enums/UserRoleEnum";

@Options({
  components: {
    ImageInput,
    PageHeader,
    StateAutocomplete
  }
})
export default class AdminPage extends Vue {
  @Prop() readonly userId!: string;

  isLoading: boolean = false;

  user: User = null;
  confirmPassword: string = null;

  get currentRole(){
    return authStore.getters.userRole;
  }

  get statusOptions() {
    return [
      { label: 'CREATED',   value: UserStatusEnum.CREATED  },
      { label: 'ACTIVE',    value: UserStatusEnum.ACTIVE },
      { label: 'DISABLED',  value: UserStatusEnum.DISABLED },
    ]
  }

  get isNew() {
    return this.userId === 'new';
  }

  get backRoute() {
    return { name: UsersRoutesEnum.ADMINS_LIST };
  }


  async loadUser() {
    this.$waitFor(
      async () => {
        this.user = await usersService.getById(+this.userId)
      }
    );
  }

  private deleteUser() {
    const message = this.$t(
      'user.delete_message',
      { name: `${this.user.name} ${this.user.surname}` }
    );

    const header = this.$t('message.confirm');

    this.$confirmMessage(message, header)
      .then((r) => {
        if (r) {

          this.$waitFor( async () => {
            await usersService.deleteBySystem(this.user);

            this.$router
            .replace(this.backRoute)
            .then( () => this.$successMessage("User successfully deleted") );
          });
        }
      });
  }

  async onSave(): Promise<any> {
    this.user.email = this.user.email.replace(/ /g,'')
    this.user.status = UserStatusEnum.ACTIVE;
    this.user.role   = UserRoleEnum.SUPER_ADMIN;

    this.$waitFor(
      async () => {

        if (this.isNew) {
          const response = await usersService.create(this.user);

          this.user = { ...this.user, ...response };

          this.$router.replace({
            name   : UsersRoutesEnum.ADMINS_DETAIL,
            params : { userId: response.id },
            force  : true
          }).then( () => 
            this.$successMessage("User successfully created")
          );
        } else {
          await usersService.updatePatch(this.user);
          this.$successMessage("User successfully updated");
        }
      },

      "Saving failed"
    )
  }

  onDelete() {
    this.deleteUser();
  }

  mounted() {
    if (!this.isNew) {
      this.loadUser();
    } else {
      this.user = new User();
    }
  }
}