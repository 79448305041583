import { User } from "@/model/api/User";
import { usersService } from "@services/users.service";
import { Options, Vue } from "vue-class-component";
import { Prop } from "vue-property-decorator";

import { UsersRoutesEnum } from "../../router";
import { authStore } from "@/modules/auth/store";
import { UserRoleEnum } from "@/model/enums/UserRoleEnum";

import {
  ContractAutocomplete,
  ImageInput,
  SendMessageDialog,
  StateAutocomplete,
  UserRoleSelect,
  CompanyAutocomplete
} from "@components";

import { UserStatusEnum } from "@/model/enums/UserStatusEnum";
import { State } from "@/model/api/State";
import { fileDownload } from "@/utils/utils";
import { Contract } from "@/model/api/Contract";
import { Company } from "@/model/api/Company";

@Options({
  components: {
    ContractAutocomplete,
    ImageInput,
    UserRoleSelect,
    SendMessageDialog,
    StateAutocomplete,
    CompanyAutocomplete
  }
})
export default class UserPage extends Vue {
  @Prop() readonly userId!: string;

  isLoading: boolean = false;

  user: User = null;
  confirmPassword: string = null;

  selectedState: string | State = null;

  licenseFileToUpload: File = null;
  insuranceFileToUpload: File = null;
  avatarFileToUpload: File = null;
  truckFileToUpload: File = null;

  displayMessageDialog: boolean = false;

  get actions() {
    const actions = [
      {
        label: 'Send message',
        icon: 'pi pi-send',
        command: () => {
          this.displayMessageDialog = true;
        }
      }
    ];

    if (!this.isNew) {
      actions.push({
        label: 'Delete',
        icon: 'pi pi-trash',
        command: () => {
          this.deleteUser();
        }
      });
    }

    return actions;
  }

  get currentRole() {
    return authStore.getters.userRole;
  }

  get roleOptions() {
    const roles = [];

    const isSA = this.currentRole === UserRoleEnum.SUPER_ADMIN;

    if (isSA) {
      roles.push(...[
        { label: 'SUPER ADMIN', value: UserRoleEnum.SUPER_ADMIN },
        { label: 'USER', value: UserRoleEnum.USER },
      ]);
    }

    return roles;
  }

  get statusOptions() {
    return [
      { label: 'CREATED', value: UserStatusEnum.CREATED },
      { label: 'ACTIVE', value: UserStatusEnum.ACTIVE },
      { label: 'DISABLED', value: UserStatusEnum.DISABLED },
    ]
  }

  get isNew() {
    return this.userId === 'new';
  }

  get isOperator() {
    return this.user?.role === UserRoleEnum.USER;
  }

  get backRoute() {
    return { name: UsersRoutesEnum.USERS_LIST };
  }

  get hasLicenseFile() {
    return this.user?.license_image_path;
  }

  get hasInsuranceFile() {
    return this.user?.insurance_image_path;
  }

  onContractSelect(contract: Contract) {
    this.user.contract_id = contract?.id;
  }

  async downloadLicenseFile() {
    if (this.user.license_image_path_url) {
      const response = await usersService
        .downloadFile(this.user.license_image_path_url);

      const fileName = this.user.license_image_path_url.split('/').pop();
      const ext = fileName.split('.').pop();

      fileDownload(
        `${fileName}`,
        response as any,
        `image/${ext}`
      )
    }
  }

  async loadUser() {
    this.$waitFor(
      async () => {
        this.user = await usersService.getById(+this.userId)
        this.selectedState = this.user.state;
      }
    );
  }

  private deleteUser() {
    const message = this.$t(
      'user.delete_message',
      { name: `${this.user.name} ${this.user.surname}` }
    );

    const header = this.$t('message.confirm');

    this.$confirmMessage(message, header)
      .then((r) => {
        if (r) {

          this.$waitFor(async () => {
            await usersService.deleteBySystem(this.user);

            this.$router
              .replace(this.backRoute)
              .then(() => this.$successMessage("User successfully deleted"));
          });
        }
      });
  }

  private async uploadFiles(userId: number) {
    return await usersService.uploadFiles(
      userId,
      {
        insuranceFile: this.insuranceFileToUpload,
        licenseFile: this.licenseFileToUpload,
        avatarImage: this.avatarFileToUpload,
        truckImage: this.truckFileToUpload
      }
    )
  }

  async onSave(): Promise<any> {
    if (!this.user.contract_id) {
      this.$errorMessage("Contract is required")
      return;
    }

    this.$waitFor(
      async () => {
        this.user.state = this.selectedState as State;
        if (this.user.state)
          this.user.state_id = this.user.state.id;
        else
          this.user.state_id = null;

        const isFileToUpload = [
          this.licenseFileToUpload,
          this.insuranceFileToUpload,
          this.avatarFileToUpload,
          this.truckFileToUpload,
        ].some(x => !!x);

        if (this.isNew) {
          const response = await usersService.create(this.user);

          if (isFileToUpload) {
            await this.uploadFiles(response.id);
            this.$successMessage("Files uploaded")
          }

          this.user = { ...this.user, ...response };

          this.$router.replace({
            name: UsersRoutesEnum.USER_DETAIL,
            params: { userId: response.id },
            force: true
          }).then(() =>
            this.$successMessage("User successfully created")
          );
        } else {
          await usersService.updatePatch(this.user);

          if (isFileToUpload) {
            await this.uploadFiles(+this.userId);
            this.$successMessage("Files uploaded")
          }

          this.$successMessage("User successfully updated");
        }
      },

      "Saving failed"
    )
  }

  onDelete() {
    this.deleteUser();
  }

  mounted() {
    if (!this.isNew) {
      this.loadUser();
    } else {
      this.user = new User();
    }
  }

  onCompanySelected(company?: Company) {
    if(company) {
      this.user.company_id = company.id
    } else {
      this.user.company = null
      this.user.company_id = null
    }
  }
}